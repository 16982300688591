import React from 'react'
import Special from '../Menhome/Special'
import Coldcut from '../Menhome/Coldcut'
import Contact from '../Menhome/Contact'
import Banner from '../Menhome/Banner'
import Menusection from '../Menhome/Menusection'
// import Specialdish from '../Menhome/Specialdish'
// import SignatureBanner from '../Menhome/SignatureBanner'
import Spirits from '../Menhome/Spirits'
// import Offer from '../Menhome/Offer'
import MixDrinks from '../Menhome/MixDrinks'
import Wine from '../Menhome/Wine'
import Whyus from '../Menhome/Whyus'
import Testimonialsabout from '../Menabout/Testimonialsabout'
import { Link } from 'react-router-dom'
import sidebarlogo from '../../assets/images/resource/sidebar-logo.png'



function Main() {
    return (
        <>
            {/* <div>
                <img src={OfferImmage} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="Nectar Hideaway" />
            </div> */}
            <div className="menu-backdrop"> </div>
            <section className="hidden-bar">
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer"><span className="far fa-close"></span></div>
                    <div className="logo-box"><Link to="/" title="Nectar Hideaway"><img src={sidebarlogo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="Nectar Hideaway" /></Link></div>
                    {/* <li><Link to="/about">About Us</Link></li> */}

                </div>
            </section>

            <Banner />
            {/* <Offer /> */}
            {/* <Story /> */}
            {/* <SignatureBanner /> */}
            <Special />
            <Coldcut />
            <Menusection />
            <MixDrinks />
            <Wine />
            <Spirits />
            <Contact />
            <Whyus />
            <Testimonialsabout />
            <div className="amazely-wall-of-love-154050708201902294"></div>
            {/* <Reacntupdate /> */}

        </>

    )
}

export default Main